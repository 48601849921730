import { FC, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import WelcomeImage from '../../assets/images/welcome-image.png';
import WelcomeFace from '../../assets/images/welcome-face.png';
import styles from './styles.module.css';
import { Button } from '../../components/Button';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { availablePharmacies } from '../../availablePharmacies';
import { availableEvents } from '../../availableEvents';

export const Welcome: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const query = useQuery();

    const handleModal = () => {
        setIsOpen(!isOpen);
    }

    const getPharmacy = (pharmacy: string) => {
        return availablePharmacies.find((e) => e.uniq_id === pharmacy);
    };

    const getEvent = (event: string) => {
        return availableEvents.find((e) => e.utm_campaign === event);
    };

    const updateTitleAndFavicon = () => {
        // Get the value from localStorage
        const appName = localStorage.getItem('pharmacyId') === 'newpharma' ? 'Newpharma Mirror' : 'Yoboo Mirror';
        const faviconUrl = localStorage.getItem('pharmacyId') === 'newpharma'  ? 'favicon.ico' : 'favicon.svg';
      
        // Update document title
        document.title = appName;
      
        // Update favicon
        const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        
        if (link) {
          link.href = faviconUrl; // Change favicon
        } else {
          // If no favicon exists, create one
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = faviconUrl;
          document.head.appendChild(newLink);
        }
    };

    useEffect(() => {
        const source = query.get('source');

        if (source) {
            localStorage.setItem('source', source);
            localStorage.setItem('resultsSent', 'true');

            return;
        }

        if (
            localStorage.getItem('pharmacyId') !== 'newpharma' &&
            localStorage.getItem('pharmacyId') !== 'teva' &&
            localStorage.getItem('pharmacyId') !== 'general'
        ) {
            localStorage.removeItem('resultsSent');
        } else {
            localStorage.setItem('resultsSent', 'true');
        }

        const utm_source = query.get('utm_source');

        if (utm_source) {
            return;
        }

        const pharmacy = query.get('pharmacy');
        const event = query.get('event');
        const utm_source_local = localStorage.getItem('utm_source');
        const utm_medium_local = localStorage.getItem('utm_medium');
        const utm_campaign_local = localStorage.getItem('utm_campaign');

        if (pharmacy) {
            if (pharmacy === 'none') {
                localStorage.removeItem('pharmacy');
                localStorage.removeItem('pharmacyIcon');
                localStorage.removeItem('pharmacyId');
        
                window.location.href = '/';

                return;
            }

            const selectedPharmacy = getPharmacy(pharmacy);

            if (selectedPharmacy) {
                localStorage.setItem('pharmacy', selectedPharmacy.display_name);
                if (selectedPharmacy.icon) {
                    localStorage.setItem('pharmacyIcon', selectedPharmacy.icon);
                } else {
                    localStorage.removeItem('pharmacyIcon');
                }
                if (selectedPharmacy.event_name) {
                    localStorage.setItem('event_name', selectedPharmacy.event_name);
                } else {
                    localStorage.removeItem('event_name');
                }
                localStorage.setItem('pharmacyId', selectedPharmacy.uniq_id);
            }

            window.location.href = '/';
        } else if (event) {
            if (event === 'none') {
                localStorage.removeItem('utm_source');
                localStorage.removeItem('utm_medium');
                localStorage.removeItem('utm_campaign');
        
                window.location.href = '/';

                return;
            }

            const selectedEvent = getEvent(event);

            if (selectedEvent) {
                localStorage.setItem('utm_source', selectedEvent.utm_source);
                localStorage.setItem('utm_medium', selectedEvent.utm_medium);
                localStorage.setItem('utm_campaign', selectedEvent.utm_campaign);

                window.location.href = `/?utm_source=${selectedEvent.utm_source}&utm_medium=${selectedEvent.utm_medium}&utm_campaign=${selectedEvent.utm_campaign}`;

                return;
            }
        }

        if (utm_source_local && utm_medium_local && utm_campaign_local) {
            window.location.href = `/?utm_source=${utm_source_local}&utm_medium=${utm_medium_local}&utm_campaign=${utm_campaign_local}`;

            return;
        }
    }, [query]);

    useEffect(() => {
        updateTitleAndFavicon();
    }, []);

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleModal}
                className={styles.modalContainer}
            >
                <DialogTitle className={styles.modalHeaderContainer}>
                    <span>{t('welcome.infoButton')}</span>
                    <span onClick={handleModal} style={{ cursor: 'pointer' }}>X</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <video className={styles.video} controls id="explainVideo">
                        <source src="https://a.storyblok.com/f/142988/x/516049398e/scan-introduction.mp4" type="video/mp4" />
                    </video>
                </DialogContent>
            </Dialog>
            <img className={styles.welcomeImage} src={WelcomeImage} alt="Welcome" />
            <Navbar isResults={false} />
            <div className={styles.container}>
                <h1 className={styles.title}>
                    <Trans i18nKey="welcome.title" components={{ span: <span className="gradient-yellow" /> }} />
                </h1>
                <p className={styles.headTitle}>{t('welcome.headTitle')}</p>
                <p className={styles.description}>{t('welcome.description')}</p>
                <img className={styles.welcomeFace} src={WelcomeFace} alt="Smile" />
                <Link to="/profile">
                    <Button text={t('welcome.startButton')} buttonType="big"  />
                </Link>
                {localStorage.getItem('pharmacyId') !== 'newpharma' && <Button text={t('welcome.infoButton')} buttonType="secondary" className={styles.infoButton} onClick={handleModal} />}
            </div>
        </div>
    );
};
