import { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Proceed, Profile, Results, Welcome } from '../Pages';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import './styles.general.css';

export const App: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#6dbf51',
      },
      secondary: {
        main: '#213163',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#F0F3FF' }}>
        <CssBaseline />
        <Container
          component='main'
          sx={{ mb: 0, mt: 0 }}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            margin: 'auto',
            maxWidth: '1440px',
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 16,
          }}
        >
          <Router>
            <Switch>
              <Route path='/results'>
                <Results />
              </Route>
              <Route path='/proceed'>
                <Proceed />
              </Route>
              <Route path='/profile'>
                <Profile />
              </Route>
              <Route path='/'>
                <Welcome />
              </Route>
            </Switch>
          </Router>
          <footer className='footer'>
            <div className='footer-container'>
              <p className='footer-text opacity-50'>{t('footer.cookiePreferences')}</p>
              <p className='footer-text opacity-50'>{t('footer.allRightsReserved')}</p>
            </div>
            <div>
              <p className='footer-text' style={{ fontWeight: 700 }}>{t('footer.feelingGood')}</p>
            </div>
          </footer>
        </Container>
      </div>
    </ThemeProvider>
  );
};
