import { Resource } from 'i18next';

const fr: Resource = {
  translation: {
    footer: {
      cookiePreferences: 'Cookie preferences',
      allRightsReserved: '© 2024 Intake BV — All rights reserved',
      feelingGood: 'yoboo - Feeling good, driven by you',
    },
    welcome: {
      title: `Curieux de connaître votre <span class="gradient-yellow">niveau d'énergie</span> ?`,
      headTitle: 'Votre visage est le miroir de votre énergie !',
      description: "Votre visage reflète comment vous vous sentez et votre état de santé. Curieux ? Avec notre scan court et simple, vous obtiendrez plus d'informations sur trois sources d'énergie importantes : l'activité physique, le repos et l'alimentation.",
      startButton: 'Commencez le scan du visage',
      infoButton: "Vidéo d'instruction",
    },
    profile: {
      error: 'Veuillez répondre à toutes les questions pour continuer',
      step0: {
        title: 'Vous êtes presque prêt à lancer le scan',
        description: "Remplissez quelques questions supplémentaires rapides pour que vos résultats reflètent correctement votre niveau d'énergie. Cela ne prend que quelques secondes.",
        button: '	Passer aux questions',
      },
      step1: {
        progressText: 'Question 1 sur 4',
        title: 'Quelques questions générales...',
        button: 'Questions suivantes',
        age: 'Quel est votre âge? *',
        gender: 'Quel était votre sexe à la naissance?',
        man: 'Homme',
        woman: 'Femme',
        smoker: 'Fumez-vous?',
        yes: 'Oui',
        no: 'Non',
      },
      step2: {
        progressText: 'Question 2 sur 4',
        title: 'Quelques questions générales...',
        button: 'Questions suivantes',
        height: 'Quelle est votre taille (en cm)? *',
        weight: 'Quel est votre poids (en kg)? *',
      },
      step3: {
        progressText: 'Question 3 sur 4',
        title: 'Quelques questions médicales...',
        info: 'Nous y sommes presque !',
        button: 'Questions suivantes',
        hypertension: "Souffrez-vous d'hypertension artérielle?",
        diabetic: 'Êtes-vous diabétique?',
        medication: "Prenez-vous un ou plusieurs médicaments contre l'hypertension artérielle?",
        yes: 'Oui',
        no: 'Non',
      },
      step4: {
        progressText: 'Question 4 sur 4',
        title: 'Vos coordonnées...',
        info: 'pour que nous puissions vous envoyer les résultats.',
        button: 'Commencez le scan du visage',
        email: 'Adresse e-mail *',
        firstname: 'Prénom *',
        lastname: 'Nom de famille',
      },
      step5: {
        title: 'Quelques contrôles de plus...',
        info: 'Après cela, le scan est lancé',
        button: 'Commencez le scan du visage',
        list: {
          item0: 'Vérifiez encore les points suivants :',
          item1: '1. Sélectionnez la bonne caméra',
          item2: '2. <strong>Autorisez</strong> votre navigateur',
          item3: '3. Positionnez votre appareil à <strong>10-15 cm</strong> de votre visage',
          item4: '4. Assurez-vous que votre visage remplisse <strong>tout le cercle</strong>',
          item5: '5. Assurez-vous que le <strong>cercle soit bien éclairé</strong> et clairement visible',
          item6: '6. <strong>Essayez de ne pas bouger ni parler</strong> une fois que le scan commence. Cela <strong>dure 30 secondes</strong>',
        },
        cookieText: "Je suis d'accord avec les",
        cookieLink: 'conditions générales',
      },
      step6: {
        title: 'Conditions générales',
        introduction: {
          p1: "Les conditions générales spécifiques Yoboo-miroir (ci-après: « conditions générales spécifiques Yoboo ») régissent la relation entre l'utilisateur des différents services Yoboo (abrégé « services Yoboo ») et Intake BV. En consultant ou en utilisant de quelque manière que ce soit les services Yoboo, l'utilisateur accepte que les conditions générales spécifiques Yoboo soient applicables.",
          p2: "Les conditions spécifiques, portées à la connaissance de chaque utilisateur et acceptées par celui-ci par l'utilisation des services Yoboo, restent pleinement applicables, y compris les modifications apportées conformément au point « validité et modification des conditions » des conditions générales spécifiques. La version la plus récente est toujours disponible sur le site web (consultable sur : <a href=\"https://www.yoboo.be/fr/legal/conditions-generale\" target=\"_blank\">https://www.yoboo.be/fr/legal/conditions-generale</a>). Si l'utilisateur n'accepte pas (ou plus) l'une ou plusieurs des dispositions énoncées dans les conditions générales spécifiques Yoboo, la consultation et/ou l'utilisation des services Yoboo ne sont plus possibles.",
        },
        identity: {
          title: "Identité de l'éditeur et du gestionnaire Yoboo",
          p1: '<strong>Intake BV</strong> : Entreprise ayant son siège social à B-2018 Anvers, Quellinstraat 12 boîte 6',
          p2: '<strong>TVA</strong> : BE0767-722-633 RPR Bruxelles',
          p3: '<strong>Service clientèle</strong> : via le formulaire de contact sur le site Yoboo.<br />Ou par courrier à Quellinstraat 12 boîte 6, 2018 Anvers'
        },
        definitions: {
          title: 'Définitions',
          p1: "<strong>Utilisateur</strong> ci-après dénommé « l'utilisateur » : l'utilisateur est une personne physique majeure qui utilise les services Yoboo. L'utilisateur n'est autorisé à utiliser les services Yoboo que pour les finalités prévues à cet effet.",
          p2: "<strong>Services Yoboo</strong> : comprennent les différents services proposés via l'application Yoboo, le portail Yoboo ou le site web Yoboo ou tout autre support par lequel Intake BV met à disposition ses services Yoboo maintenant et à l'avenir.",
        },
        purpose: {
          title: 'Objectif des Conditions Générales Spécifiques',
          p1: "Avec ces conditions générales spécifiques ainsi que les conditions générales de tous les services Yoboo, Intake BV souhaite fournir à l'utilisateur les informations essentielles concernant les droits et obligations liés à l'utilisation des services Yoboo.",
          p2: "Responsabilités de l'utilisateur :",
          list: {
            item1: "L'utilisation des services Yoboo est interdite aux personnes de moins de 18 ans.",
            item2: "L'utilisation des services Yoboo n'est possible que si l'utilisateur possède un code d'activation.",
            item3: "Le code d'accès pour activer les services Yoboo est personnel et ne peut être transmis à ou utilisé par une autre personne.",
            item4: "L'utilisateur est responsable de la confidentialité et du caractère personnel de ses informations de compte.",
            item5: "L'utilisateur est responsable de remplir correctement les informations demandées.",
          },
        },
        general: {
          title: 'Conditions Générales Yoboo',
          p1: "Inscription : pour accéder aux différents services Yoboo, l'utilisateur doit s'inscrire via le site web Yoboo.",
          p2: "Toute utilisation commerciale des services Yoboo est strictement interdite. Par utilisation commerciale, on entend notamment la vente ou la location des différentes fonctionnalités des services Yoboo, ou l'utilisation des services Yoboo et de ses composants dans le seul but de générer des revenus publicitaires ou d'abonnement, ou plus généralement l'organisation d'une concurrence déloyale ou la réalisation d'actes contraires aux pratiques commerciales loyales vis-à-vis de Intake BV, ou la commercialisation ou la réutilisation de quelque manière que ce soit du contenu, des images ou de tout autre matériel mis à disposition via les services Yoboo. Cette liste n'est pas exhaustive.",
          p3: "L'utilisateur reste seul responsable de l'utilisation correcte des services Yoboo. L'utilisateur indemnisera intégralement Intake BV pour tous les dommages et frais résultant de la fourniture d'informations incorrectes ou incomplètes lors de la gestion des services Yoboo ou de l'utilisation/mauvaise utilisation des services Yoboo.",
          p4: "En acceptant les conditions générales supplémentaires des différents services, les données que l'utilisateur a saisies sur le site web Yoboo seront utilisées dans les différents services et vice versa.",
          p5: "Toutes les données obtenues de l'utilisateur via l'utilisation du Yoboo-miroir par Intake BV ne sont pas conservées et sont immédiatement supprimées après utilisation. Les données personnelles de l'utilisateur seront traitées conformément à la politique de confidentialité de Intake BV. Lors de l'utilisation du Yoboo-miroir, seuls le nom et l'adresse e-mail sont conservés par Intake BV.",
          p6: "Le résultat de l'utilisation des services est envoyé par e-mail.",
        },
        website: {
          title: 'Utilisation du site web :',
          p1: "L'utilisateur est conscient des limitations et des risques de l'utilisation d'internet ou de tout autre moyen par lequel Intake BV met à disposition ses services maintenant et à l'avenir. L'utilisateur est également conscient des risques liés au stockage et à la transmission numérique ou électronique d'informations. L'utilisateur accepte qu'Intake BV ne soit pas responsable de tout dommage résultant de l'utilisation de leurs sites web ou d'internet en raison des risques susmentionnés. Intake BV ne peut être tenue responsable du contenu des sites web ou des liens mis à disposition sur leur site web. Le simple fait que ces liens soient disponibles sur le site web d'Intake BV ne constitue en aucun cas une approbation, acceptation ou reconnaissance (du contenu de) ces autres sites web. De plus, il est de la responsabilité de l'utilisateur de vérifier si le site web est exempt de virus et d'autres programmes ou éléments pouvant être nuisibles à son équipement. L'utilisateur doit toujours consulter les conditions et/ou avertissements applicables de ces autres sites web/apps et les accepter en conséquence.",
          p2: "L'utilisateur est tenu de vérifier régulièrement si la version la plus récente du site web Yoboo est installée afin de disposer des informations les plus récentes. Intake BV n'est en aucun cas responsable des décisions, de quelque nature que ce soit, prises par l'utilisateur sur la base des informations obtenues via l'application.",
        },
        liability: {
          title: "Responsabilité d'Intake BV :",
          p1: 'Les conditions générales, les avertissements et autres conditions restent pleinement applicables en plus des conditions générales des autres services Yoboo.',
          p2: "Bien que les services Yoboo prennent le plus grand soin, il est possible que l'information soit incomplète, incorrecte, non adaptée à une situation particulière, ou non entièrement à jour.",
          p3: "Sauf en cas de dommages résultant de fraude, de faute intentionnelle ou de négligence grave de la part d'Intake BV, de ses préposés ou mandataires, Intake BV et/ou ses administrateurs, actionnaires, agents et représentants ne peuvent être tenus responsables de tout dommage direct, indirect, accessoire ou consécutif.",
          p4: "La responsabilité contractuelle et extracontractuelle d'Intake BV et de ses administrateurs, actionnaires, agents et représentants sera à tout moment limitée aux montants couverts par l'assurance responsabilité souscrite par Intake BV. Cette couverture est limitée à un maximum de 20 000 euros par sinistre mais est limitée à 20 000 euros par an pour tous les sinistres combinés.",
          p5: "Les limitations de responsabilité précédentes ne s'appliquent pas à la responsabilité légale d'Intake BV et de ses administrateurs, actionnaires, agents et représentants en cas de décès ou de blessure corporelle du consommateur résultant de leurs actes ou omissions.",
          p6: "Les services Yoboo ne peuvent être qualifiés de services à des fins médicales. Les services ne constituent pas un avis médical, un diagnostic ou un traitement et ne sont pas destinés à un usage ou une compréhension médicale. Les services visent à fournir à l'utilisateur une compréhension de son mode de vie et à donner des lignes directrices pour améliorer son mode de vie. Les informations sur la santé sont des données de nature physiologique et psychologique, mais ne peuvent être considérées comme un avis médical. Les informations fournies par les services Yoboo ne sont pas destinées à être utilisées à des fins médicales ou pour diagnostiquer, traiter, guérir ou prévenir une maladie, un état ou une blessure. Ces informations ne remplacent pas l'avis ou le diagnostic d'un médecin ou d'un professionnel de santé et/ou une consultation auprès d'un médecin ou d'un autre professionnel de santé.",
          p7: "Les données obtenues par l'utilisation du Yoboo-miroir sont traitées selon une prise de décision automatisée.",
        },
        access: {
          title: 'Accès et utilisation des services Yoboo :',
          p1: "Il est strictement interdit, de quelque manière que ce soit et avec quelque moyen technique que ce soit, de tenter d'accéder à des parties qui ne sont pas publiquement accessibles. Il est également strictement interdit de modifier, adapter, ajouter ou supprimer, de quelque manière que ce soit et avec quelque moyen technique que ce soit, les services Yoboo, à l'exclusion des possibilités et des espaces prévus à cet effet.",
          p2: "L'utilisateur s'engage à ne poser aucun acte, quel que soit le moyen technique utilisé, qui pourrait à un moment donné compromettre de quelque manière que ce soit le bon fonctionnement des services Yoboo.",
        },
        validity: {
          title: "Validité et modification des conditions :",
          p1: "Si une disposition des conditions générales spécifiques Yoboo est jugée nulle, invalide, inapplicable ou illégale, les autres dispositions restent pleinement en vigueur. La lacune résultant de la disposition nulle, invalide, inapplicable ou illégale des conditions générales spécifiques Yoboo sera comblée conformément à la législation en vigueur par l'application d'une disposition valide qui se rapproche le plus de l'objectif économique initial des conditions.",
          p2: "Le fait que Yoboo renonce à exiger l'application stricte d'une ou plusieurs des dispositions des présentes conditions ne peut être interprété comme une renonciation implicite aux droits dont elle dispose et n'empêche pas d'exiger ultérieurement leur stricte application.",
          p3: "Intake BV se réserve le droit d'apporter des modifications aux présentes conditions générales spécifiques Yoboo à tout moment. En cas de modification des conditions générales spécifiques Yoboo, l'utilisateur en sera toujours informé et il lui sera demandé de les accepter à nouveau s'il souhaite continuer à utiliser les services Yoboo.",
          p4: '<strong>Droits de propriété intellectuelle</strong>',
          p5: "Le concept, le contenu, la mise en page, la structure, les codes sources, les logiciels, les images, les photos, les recettes, les animations, les œuvres audiovisuelles, les textes, les données, la musique et tous les autres éléments des services Yoboo sont et restent la propriété exclusive d'Intake BV ou des partenaires avec lesquels Intake BV collabore et sont protégés par divers droits de propriété intellectuelle (notamment le droit d'auteur, le droit des marques, le droit des logiciels, le droit des dessins et modèles, le droit des brevets, le droit des bases de données, le droit sui generis du producteur d'une base de données, et/ou d'autres droits de propriété intellectuelle ou droits d'exclusivité) ce qui est reconnu et accepté par l'utilisateur. En utilisant les services Yoboo, l'utilisateur n'acquiert aucun droit à cet égard.",
          p6: "La reproduction, la distribution, la vente, la diffusion, la publication, l'adaptation et/ou l'utilisation, totale ou partielle, sous quelque forme que ce soit, de quelque manière que ce soit du site web Yoboo et de son contenu sont strictement interdites. Le stockage de toute information et/ou partie de l'application dans une base de données (électronique) ou autrement n'est pas autorisé. En publiant certaines données, textes, images ou autres fichiers en ligne, l'utilisateur autorise automatiquement et gratuitement Intake BV à reproduire, communiquer ou autrement utiliser le contenu du message dans le monde entier, tant dans l'application que dans tout autre message publicitaire ou commercial. L'utilisateur garantit qu'il est habilité à donner cette autorisation et, par exemple dans le cas d'œuvres protégées par le droit d'auteur, qu'il dispose de l'autorisation de l'auteur.",
        },
        questions: {
          title: 'Questions ou plaintes :',
          p1: "L'utilisateur peut, pour toute question ou plainte, utiliser en premier lieu le formulaire de contact sur le site web Yoboo. Si aucune conciliation n'est trouvée, une médiation sera initiée en concertation mutuelle. Si cette médiation ne résout pas le problème, le litige sera soumis aux tribunaux compétents de Belgique et sera régi par le droit belge.",
          p2: "Si le client ne réside pas en Belgique, il peut également contacter le service clientèle en premier lieu. Si aucun accord n'est trouvé, le client peut également contacter la plateforme européenne de résolution des litiges en ligne (<a href=\"https://ec.europa.eu/odr\" target=\"_blank\">https://ec.europa.eu/odr</a>) pour les questions en ligne dans sa langue.",
        },
        privacy: {
          title: 'Protection de la vie privée des données personnelles :',
          p1: "La fourniture des services tels que décrits dans les présentes conditions générales nécessite la collecte et le traitement des données personnelles de l'utilisateur. La protection des données personnelles de l'utilisateur est extrêmement importante pour Intake BV. Intake BV s'engage donc à protéger ces données au mieux et à garantir les droits de l'utilisateur lors de l'utilisation de ces données, conformément aux règles applicables en matière de protection de la vie privée et des données personnelles.",
          p2: "Intake BV traite les données personnelles de l'utilisateur pour les finalités et sur les bases légales décrites dans la Déclaration de confidentialité. La Déclaration de confidentialité est consultable en ligne ou peut être demandée via le service clientèle. Cette Déclaration de confidentialité peut être modifiée de temps à autre.",
          p3: "L'utilisateur s'engage à informer en temps voulu le service clientèle de toute modification concernant son adresse e-mail habituelle, ses informations d'identification et ses coordonnées afin de permettre à Intake BV de fournir un service correct.",
        },
        conflicts: {
          title: 'Contradictions :',
          p1: 'En cas de contradiction entre les présentes conditions générales et les conditions particulières supplémentaires des services spécifiques Yoboo, les conditions particulières supplémentaires prévaudront sur les conditions générales.',
        }
      },
    },
    details: {
      title: "Passons à l'action!",
      step1: {
        title: 'Accès à la caméra',
        description: "Sur <strong>l'écran suivant</strong>, lorsque vous lancez l'analyse, <strong>vous devez autoriser\nvotre navigateur</strong> à effectuer les mesures.",
        imageText: 'Cela se présente comme suit:',
      },
      step2: {
        title: 'Positionnez votre appareil',
        list: {
          item1: 'Tenez votre appareil à <strong>10-15 cm devant votre visage</strong>.',
          item2: 'Veillez à ce que votre visage remplisse tout le cercle.',
          item3: "Jusqu'à ce que le cercle soit bien éclairé et clairement visible.",
        }
      },
      step3: {
        title: 'Exécution du scan',
        list: {
          item1: 'Le <strong>scan démarre automatiquement</strong> lorsque les <strong class="green-text-details">cercles verts</strong> commencent à tourner.',
          item2: 'Gardez votre <strong>visage au centre</strong>, près de la caméra et ne bougez pas.',
          item3: 'Essayez de <strong>ne pas bouger ou de ne pas parler<strong>.'
        }
      },
      button: 'Executer le scan!',
    },
    results: {
      results_done: {
        title: 'Le scan est complet, super !',
        description: "Votre chemin vers l'énergie est à portée de clic ! Vous recevrez également vos résultats par <strong>e-mail</strong> et là vous pourrez poursuivre votre trajet.",
        button: 'Voir vos résultats',
      },
      navbarButton: {
        energy: 'Montrez-moi les chiffres!',
        measurements: "Retour au rapport sur l'énergie",
      },
      error: {
        title: 'Impossible de lancer le scan',
        description: "Vos modifications ont été enregistrées, mais nous n'avons pas pu lancer l'analyse en raison d'un problème technique. Veuillez réessayer.",
        button: 'Réessayer',
      },
      header: {
        energy: {
          title: "Votre score d'énergie",
          description: "Découvrez comment vous vous situez par rapport aux <strong>sources d'énergie</strong> que sont le mouvement, le repos et la nutrition. Plus votre batterie est pleine, plus votre source d'énergie est équilibrée, et plus vous vous sentirez énergique.",
          info: "Vos données sont traitées de manière confidentielle et ne sont pas partagées avec des tiers.",
        },
        measurements: {
          title: 'Analyses basées sur le scan',
          description: "Un aperçu des mesures et des risques associés.",
        },
      },
      measurementsTableHeader1: {
        value: 'Valeur',
        name: 'Mesure',
      },
      measurementsTableHeader2: {
        value: 'Pourcentage',
        name: 'Risque',
      },
      measurements: {
        title1: 'Mesures',
        title2: 'Risques',
        HR_BPM: 'Fréquence cardiaque (par minute)',
        IHB_COUNT: 'Nombre de battements cardiaques irréguliers',
        BR_BPM_STRICT: 'Fréquence respiratoire (par minute)',
        BP_SYSTOLIC: 'Pression artérielle systolique (mmHg)',
        BP_DIASTOLIC: 'Pression artérielle diastolique (mmHg)',
        HRV_SDNN: 'Variabilité de la fréquence cardiaque (millisecondes)',
        BP_RPP: 'Charge de travail cardiaque (Fréquence cardiaque x Pression artérielle systolique)',
        BP_TAU: 'Capacité vasculaire (secondes)',
        AGE: 'Âge de la peau du visage (ans)',
        HBA1C_RISK_PROB: "Risque d'hémoglobine A1C",
        DBT_RISK_PROB: 'Risque de diabète de type 2',
        HDLTC_RISK_PROB: "Risque d'hypertriglycéridémie",
        HPT_RISK_PROB: "Risque d'hypertension",
      },
      physical: {
        title: 'Mouvement',
        description: "La batterie reflète la proportion entre le mouvement, la position debout et la position assise dans votre vie quotidienne. Plus l'équilibre est respecté, plus la batterie est pleine.",
        measurements: {
          body_mass_index: 'Indice de masse corporelle (IMC) (10 - 60)',
          facial_skin_age: 'Âge de la peau du visage (13 - 120)',
          waist_to_height_ratio: 'Rapport taille à hauteur (25 - 70)',
          body_shape_index: 'Indice de forme corporelle (6.19 - 8.83)',
          height: 'Taille (cm)',
          weight: 'Poids (kg)',
          estimated_waist_circumference: 'Circonférence estimée de la taille',
        },
      },
      mental: {
        title: 'Repos',
        description: "Comment gérez-vous le stress et les émotions ? Plus vous êtes fort dans ce domaine, plus la batterie est pleine.",
        measurements: {
          mental_stress_index: 'Indice de stress mental (1 - 5.9)'
        },
      },
      metabolic: {
        title: 'Nutrition',
        description: 'Votre corps transforme-t-il facilement la nourriture en énergie ? Plus cette transformation est efficace, plus la batterie est pleine.',
        measurements: {
          hypertension_risk: "Risque d'hypertension (0 - 100%)",
          type_2_diabetes_risk: 'Risque de diabète de type 2 (0 - 100%)',
          hypercholesterolemia_risk: "Risque d'hypercholestérolémie (0 - 100%)",
          hypertriglyceridemia_risk: "Risque d'hypertriglycéridémie (0 - 100%)",
        },
      },
      preFooter: {
        title: 'Curieux de connaître le fonctionnement de notre Scan du Visage ?',
        description: "Vous vous demandez peut-être comment nous pouvons partager toutes ces informations avec vous à partir d'un scan de visage.<br /><br />Votre <strong>visage reflète vos niveaux d'énergie</strong>. Grâce à la technologie appropriée, il est possible de détecter dans votre visage des <strong>changements de couleur sous-jacents</strong> qui en disent plus sur le <strong>flux sanguin</strong> dans votre visage. Cela est directement lié à votre cœur et, grâce à <strong>divers algorithmes</strong>, une valeur réaliste de votre fréquence cardiaque, de votre respiration et de la variabilité de votre fréquence cardiaque peut être déterminée.<br /><br />Ces valeurs, ainsi que d'autres, donnent une indication de vos niveaux d'énergie. Intéressant, n'est-ce pas ? ",
      },
      footer: {
        title: "Souhaitez-vous plus d'informations?",
        description: "Souhaitez-vous obtenir plus d'informations et d'accompagnement en matière d'énergie et de mode de vie sain, de manière ludique et accessible ? Découvrez ici comment nous pouvons vous aider à recharger vos batteries.",
        button: "Je veux plus d'énergie",
        buttonNP: 'Refaire le scan'
      },
    },
    proceed: {
      slide1: {
        title: 'Votre tableau de bord personnel',
        description: "Vous recevrez dans votre boîte mail une invitation à passer le <strong>test Willbeing®</strong>, car chez Yoboo, nous sommes convaincus que <strong>votre motivation est le point de départ</strong> d'un mode de vie plus énergique et plus sain. Test rempli ? Super ! Vous trouvez les résultats sur votre <strong>tableau de bord personnel</strong>.",
      },
      slide2: {
        title: 'Coaching individuel',
        description: "Lors d'un <strong>contact personnel</strong> avec un coach yoboo, vous recevez des <strong>conseils et des informations</strong> sur les piliers de yoboo. Ensemble, nous déterminerons les <strong>actions</strong> que vous pouvez et voulez intégrer en rapport avec la <strong>nutrition, l'exercice et le repos</strong>.",
      },
      slide3: {
        title: 'Prêt à partir',
        description: "Commencez dès maintenant à votre rythme. Le coach yoboo vous fournit un <strong>schéma d'action</strong> et notre <strong>application yoboo</strong> vous inspire et vous motive dans votre parcours personnel.",
      },
    },
  },
};

export default fr;
