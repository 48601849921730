export const LogoNP = ({ width = "100%" }: { width: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height="100%" viewBox="0 0 475.54 106.98">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path fill="#6dbf51"
                        d="M30.67,34.64V53.82a3.39,3.39,0,0,1-3.44,3.54,3.49,3.49,0,0,1-3.44-3.54V35c0-5.63-3.71-8.11-8.24-8.11a17,17,0,0,0-8.49,2.17V53.82a3.43,3.43,0,0,1-3.44,3.54A3.53,3.53,0,0,1,.15,53.82V26.64a2.94,2.94,0,0,1,1.34-2.79c2.06-1.44,7-3.71,14.23-3.71C25.62,20.14,30.67,25.4,30.67,34.64Z"/>
                    <path fill="#6dbf51"
                        d="M70.43,35.33v2.06c0,2.51-1.34,4.23-4.44,4.23H46.23v1c0,5.71,4,8.76,9.59,8.76A17,17,0,0,0,63.07,50a5.73,5.73,0,0,1,2.37-.69,3.1,3.1,0,0,1,3.06,3.09,3.36,3.36,0,0,1-2.2,3.06,24.92,24.92,0,0,1-10.69,2.2c-9.55,0-15.32-6.05-15.94-14.77a70.33,70.33,0,0,1,0-8.49c.69-8.83,6.91-14.3,15.57-14.3C64.45,20.14,70.43,26.26,70.43,35.33ZM46.23,36.4H63.11c.61,0,.85-.24.89-.93.38-5.64-3.2-9.35-8.69-9.35C49.12,26.12,46.13,30.07,46.23,36.4Z"/>
                    <path fill="#213163"
                        d="M165.34,33.92a58.06,58.06,0,0,1,0,9.69c-.69,8.08-6.36,14-15,14a22.53,22.53,0,0,1-8.83-2V67a3.54,3.54,0,0,1-3.44,3.54A3.43,3.43,0,0,1,134.58,67V26.53a2.28,2.28,0,0,1,1.1-2.16,25.74,25.74,0,0,1,14-4.23C158.53,20.14,164.58,25.74,165.34,33.92Zm-23.85-4.64V48.7a16.71,16.71,0,0,0,8.28,2.23c5.15,0,8.32-3.16,8.73-8.07a44.26,44.26,0,0,0,0-8c-.45-4.81-3.68-8.11-8.9-8.11A15.66,15.66,0,0,0,141.49,29.28Z"/>
                    <path fill="#213163"
                        d="M181.83,11.52V24a15.06,15.06,0,0,1,10-3.82c8.28,0,13.57,4.92,13.57,14.37V53.78A3.39,3.39,0,0,1,202,57.36a3.5,3.5,0,0,1-3.44-3.58V35c0-5.12-2.75-8.11-7.63-8.11a12.31,12.31,0,0,0-9.07,4.47V53.82a3.44,3.44,0,0,1-3.4,3.54A3.52,3.52,0,0,1,175,53.82V11.52a3.42,3.42,0,0,1,3.43-3.58A3.5,3.5,0,0,1,181.83,11.52Z"/>
                    <path fill="#213163"
                        d="M243.1,33.37v18A2.77,2.77,0,0,1,241.79,54c-1.85,1.3-6.83,3.74-14.12,3.74-9.35,0-13.92-5-13.92-11.65,0-6,4.33-11.17,13.64-11.17a32.4,32.4,0,0,1,9,1.31V33.51c0-4.78-2.27-7-7.76-7a19.93,19.93,0,0,0-7.5,1.59,5.42,5.42,0,0,1-1.85.37,3.09,3.09,0,0,1-3.09-3.05,3.28,3.28,0,0,1,2.23-3.13,25.11,25.11,0,0,1,10.21-2.1C238.05,20.14,243.1,24.13,243.1,33.37ZM220.35,46c0,3.78,2.75,5.91,7.87,5.91a15.34,15.34,0,0,0,8.21-2.44V41.48a28.87,28.87,0,0,0-8.24-1.37C222.82,40.11,220.35,42.75,220.35,46Z"/>
                    <path fill="#213163"
                        d="M270.9,20.83a3.36,3.36,0,0,1,2.82,3.3,3.22,3.22,0,0,1-3.13,3.37,13.3,13.3,0,0,1-2.27-.35,16,16,0,0,0-2.82-.24c-3.5,0-5.29,1.62-5.29,5.47V53.85a3.44,3.44,0,0,1-3.44,3.51,3.51,3.51,0,0,1-3.47-3.51V31.31c0-6.91,4.06-11.17,11.55-11.17A24.11,24.11,0,0,1,270.9,20.83Z"/>
                    <path fill="#213163"
                        d="M327.94,33.89V53.82a3.44,3.44,0,1,1-6.87,0V34.4c0-4.88-2.2-7.49-7-7.49a11.56,11.56,0,0,0-7.32,2.79,21.62,21.62,0,0,1,.34,4.39V53.82a3.44,3.44,0,1,1-6.87,0V34.33c0-5.12-2.24-7.42-7.12-7.42a15,15,0,0,0-6.87,2.17V53.82a3.46,3.46,0,1,1-6.91,0V26.64a3,3,0,0,1,1.34-2.79A24.08,24.08,0,0,1,293,20.14c4.78,0,7.77,1.51,10.1,3.92a19.18,19.18,0,0,1,11.31-3.92C323.92,20.14,327.94,25.33,327.94,33.89Z"/>
                    <path fill="#6dbf51"
                        d="M76.82,43.61V23.68a3.44,3.44,0,1,1,6.88,0V43.1c0,4.88,2.19,7.49,7,7.49a11.66,11.66,0,0,0,7.32-2.78,21.79,21.79,0,0,1-.34-4.4V23.68a3.44,3.44,0,1,1,6.87,0V43.17c0,5.12,2.23,7.42,7.11,7.42a15,15,0,0,0,6.88-2.17V23.68a3.45,3.45,0,1,1,6.9,0V50.86a2.94,2.94,0,0,1-1.34,2.79,24,24,0,0,1-12.33,3.71c-4.78,0-7.77-1.51-10.11-3.92a19.12,19.12,0,0,1-11.3,3.92C80.84,57.36,76.82,52.17,76.82,43.61Z"/>
                    <path fill="#213163"
                        d="M365.64,33.37v18A2.77,2.77,0,0,1,364.33,54c-1.85,1.3-6.84,3.74-14.12,3.74-9.35,0-13.92-5-13.92-11.65,0-6,4.33-11.17,13.64-11.17a32.46,32.46,0,0,1,9,1.31V33.51c0-4.78-2.27-7-7.76-7a19.93,19.93,0,0,0-7.5,1.59,5.42,5.42,0,0,1-1.85.37,3.09,3.09,0,0,1-3.09-3.05A3.28,3.28,0,0,1,341,22.24a25.07,25.07,0,0,1,10.21-2.1C360.59,20.14,365.64,24.13,365.64,33.37ZM342.89,46c0,3.78,2.75,5.91,7.87,5.91A15.34,15.34,0,0,0,359,49.42V41.48a28.88,28.88,0,0,0-8.25-1.37C345.36,40.11,342.89,42.75,342.89,46Z"/>
                    <path fill="#213163"
                        d="M12.67,79H10.79c-.36,1.24-.69,2.41-1,3.5s-.63,2.13-.93,3.11S8.26,87.55,8,88.46s-.54,1.79-.8,2.64c-.19.61-.37,1.22-.56,1.82H6.17q-.37-1.24-.75-2.52l-1-3.34c-.34-1.14-.69-2.35-1-3.62L2.22,79.37a1.86,1.86,0,0,0-.08-.23.15.15,0,0,0-.08-.08A.2.2,0,0,0,2,79H0c.12.4.23.8.34,1.19s.23.78.36,1.16.24.77.34,1.15c.25.85.5,1.69.74,2.54s.49,1.69.73,2.52S3,89.22,3.23,90s.47,1.53.68,2.24.42,1.36.61,2a1.81,1.81,0,0,0,.15.38.38.38,0,0,0,.27.08H8.2c.2-.66.44-1.41.7-2.26s.52-1.72.78-2.65.54-1.88.83-2.84.57-1.9.84-2.83.54-1.81.79-2.63.47-1.54.67-2.16a.42.42,0,0,0,0-.16l-.06-.07A.12.12,0,0,0,12.67,79Z"/>
                    <path fill="#213163"
                        d="M22.85,83.89a4.79,4.79,0,0,0-1.6-1,5.51,5.51,0,0,0-2.06-.37,5.35,5.35,0,0,0-2.6.63,4.63,4.63,0,0,0-1.8,1.74,5,5,0,0,0-.66,2.6v2.45a5.13,5.13,0,0,0,.36,1.95,4.26,4.26,0,0,0,1,1.54,4.5,4.5,0,0,0,1.6,1,5.73,5.73,0,0,0,2.07.36,5.21,5.21,0,0,0,2.59-.64,4.59,4.59,0,0,0,1.8-1.75,5,5,0,0,0,.66-2.58V87.36a5.2,5.2,0,0,0-.36-2A4.1,4.1,0,0,0,22.85,83.89Zm-.63,5.87a3.33,3.33,0,0,1-.37,1.59,2.83,2.83,0,0,1-1.06,1.14,3.18,3.18,0,0,1-1.72.43,2.92,2.92,0,0,1-1.16-.22,2.63,2.63,0,0,1-.9-.6,3.28,3.28,0,0,1-.63-1,3.65,3.65,0,0,1-.23-1.32V87.54a3.45,3.45,0,0,1,.37-1.62,2.8,2.8,0,0,1,1.06-1.13,3.27,3.27,0,0,1,1.72-.42,3.41,3.41,0,0,1,1.19.2,2.41,2.41,0,0,1,.89.58,2.45,2.45,0,0,1,.63,1,4.07,4.07,0,0,1,.21,1.37Z"/>
                    <path fill="#213163"
                        d="M34.58,82.76H30.51V79.28a.16.16,0,0,0,0-.12.2.2,0,0,0-.09-.09.21.21,0,0,0-.12,0H28.47v3.73H26.23v1.66a.29.29,0,0,0,0,.09l.06.07a.24.24,0,0,0,.08.06l.08,0h2v5.41a6.09,6.09,0,0,0,.29,2,3.32,3.32,0,0,0,.86,1.39,3.52,3.52,0,0,0,1.4.8,6.39,6.39,0,0,0,1.9.25h1.66a.25.25,0,0,0,.19-.06.28.28,0,0,0,.06-.18V92.61H33a4.4,4.4,0,0,1-1.1-.12,1.62,1.62,0,0,1-.74-.41,1.82,1.82,0,0,1-.51-.86,5,5,0,0,1-.15-1.33V84.66h4.32V83a.29.29,0,0,0-.07-.18A.24.24,0,0,0,34.58,82.76Z"/>
                    <path fill="#213163"
                        d="M45.13,82.73a2.55,2.55,0,0,0-.46-.14,3.2,3.2,0,0,0-.67-.06,3.86,3.86,0,0,0-1.39.25,3.33,3.33,0,0,0-1.17.78,5.92,5.92,0,0,0-1,1.46h-.35V83a.22.22,0,0,0,0-.13.3.3,0,0,0-.08-.08.21.21,0,0,0-.13,0H38V94.3a.37.37,0,0,0,0,.11.3.3,0,0,0,0,.08l.08,0h2V87a7.79,7.79,0,0,1,.88-1.2,3.68,3.68,0,0,1,1.3-.91,3.86,3.86,0,0,1,1.45-.27,6.38,6.38,0,0,1,.66,0,4.55,4.55,0,0,1,.6.12h.33V83.07a.58.58,0,0,0,0-.21A.23.23,0,0,0,45.13,82.73Z"/>
                    <path fill="#213163"
                        d="M54.12,83.06a5.16,5.16,0,0,0-2.39-.55,6.18,6.18,0,0,0-1.95.31,4.64,4.64,0,0,0-1.6.93,4.37,4.37,0,0,0-1.07,1.58,5.85,5.85,0,0,0-.39,2.23v2.31A5.68,5.68,0,0,0,47.1,92a4,4,0,0,0,1.1,1.53,4.67,4.67,0,0,0,1.75.94,8.13,8.13,0,0,0,2.29.31A10,10,0,0,0,54,94.64a7.9,7.9,0,0,0,1.42-.36,2.41,2.41,0,0,0,.81-.45.24.24,0,0,0,.06-.09.13.13,0,0,0,0-.08V92.17H56a7.43,7.43,0,0,1-1.08.44,6.78,6.78,0,0,1-1.14.27,10.13,10.13,0,0,1-1.45.09,6,6,0,0,1-1.68-.21,2.64,2.64,0,0,1-1.16-.66,2.59,2.59,0,0,1-.55-.95,3.7,3.7,0,0,1-.2-1.28v-.51h7.53a.17.17,0,0,0,.13,0,.3.3,0,0,0,.08-.08.28.28,0,0,0,0-.13V87.5a5.41,5.41,0,0,0-.65-2.81A4.13,4.13,0,0,0,54.12,83.06Zm.4,4.64v.22H48.74v-.38a3.42,3.42,0,0,1,.77-2.49,3,3,0,0,1,2.22-.77,3.71,3.71,0,0,1,1.15.17,2.34,2.34,0,0,1,.87.5,2.41,2.41,0,0,1,.58,1,5.11,5.11,0,0,1,.19,1.5Z"/>
                    <path fill="#213163"
                        d="M74.13,83a3.92,3.92,0,0,0-2-.52,3.69,3.69,0,0,0-1.41.27,4.23,4.23,0,0,0-1.18.73,4.67,4.67,0,0,0-1,1.17h-.31V83a.22.22,0,0,0,0-.13l-.07-.08a.26.26,0,0,0-.14,0H66.22v16a.27.27,0,0,0,0,.12.2.2,0,0,0,.09.09.27.27,0,0,0,.12,0h1.8V93h.31a5,5,0,0,0,1,1,3.74,3.74,0,0,0,1.16.59,4.43,4.43,0,0,0,1.38.2,4,4,0,0,0,1.59-.3,3.32,3.32,0,0,0,1.23-.88,4.05,4.05,0,0,0,.8-1.41A5.91,5.91,0,0,0,76,90.31V86.85a5.19,5.19,0,0,0-.49-2.32A3.65,3.65,0,0,0,74.13,83ZM74,90a3.55,3.55,0,0,1-.3,1.54,2.16,2.16,0,0,1-.83.95,2.4,2.4,0,0,1-1.29.32,4.48,4.48,0,0,1-2-.45,3.93,3.93,0,0,1-1.3-1.06V86.64a6.1,6.1,0,0,1,.83-1,4.25,4.25,0,0,1,1.19-.85,3,3,0,0,1,1.25-.28,2.78,2.78,0,0,1,1,.16,1.93,1.93,0,0,1,.74.46,2.25,2.25,0,0,1,.51.85A3.8,3.8,0,0,1,74,87.16Z"/>
                    <path fill="#213163"
                        d="M87.63,83.44a2.65,2.65,0,0,0-1-.68,3.3,3.3,0,0,0-1.34-.25,3.66,3.66,0,0,0-1.1.16,3.81,3.81,0,0,0-1,.45,5.51,5.51,0,0,0-.9.71,10.34,10.34,0,0,0-.82.9h-.33V78.17a.23.23,0,0,0,0-.13L81,78a.25.25,0,0,0-.13,0h-1.8V94.3a.23.23,0,0,0,0,.13l.09.08a.27.27,0,0,0,.12,0h1.8V86.45c.23-.25.45-.48.67-.68a6.58,6.58,0,0,1,1-.73,4,4,0,0,1,1-.43,3.19,3.19,0,0,1,.95-.15,1.7,1.7,0,0,1,1.23.43,1.83,1.83,0,0,1,.43,1.34V94.3a.23.23,0,0,0,0,.13.24.24,0,0,0,.09.08.27.27,0,0,0,.12,0h1.8v-8.7a4.05,4.05,0,0,0-.21-1.34A3.15,3.15,0,0,0,87.63,83.44Z"/>
                    <path fill="#213163"
                        d="M101.92,92.75h-.1a1.35,1.35,0,0,1-.38,0,.61.61,0,0,1-.26-.15.71.71,0,0,1-.17-.31,1.77,1.77,0,0,1-.06-.49v-5a6.12,6.12,0,0,0-.27-2,3,3,0,0,0-.86-1.31,3.4,3.4,0,0,0-1.47-.74,8.36,8.36,0,0,0-2.14-.24,9.88,9.88,0,0,0-1.74.15,10,10,0,0,0-1.39.32,1.78,1.78,0,0,0-.68.31.43.43,0,0,0,0,.07.18.18,0,0,0,0,.11v1.59h.33a8.34,8.34,0,0,1,1.14-.36A7.41,7.41,0,0,1,95,84.51a10,10,0,0,1,1.24-.07,5,5,0,0,1,1.62.21,1.45,1.45,0,0,1,.85.71,3.05,3.05,0,0,1,.26,1.38v1H95a4.63,4.63,0,0,0-1.44.22,3.3,3.3,0,0,0-1.09.62,2.59,2.59,0,0,0-.7,1,3.45,3.45,0,0,0-.25,1.32v.71a3.07,3.07,0,0,0,.43,1.67,2.86,2.86,0,0,0,1.17,1.09,4,4,0,0,0,1.79.38,5.2,5.2,0,0,0,1.62-.24,3.81,3.81,0,0,0,1.28-.71,4,4,0,0,0,.91-1.15H99a2.44,2.44,0,0,0,.14.5,2,2,0,0,0,.5.76,2.29,2.29,0,0,0,.78.47,3,3,0,0,0,.95.15h.78a.35.35,0,0,0,.18-.05.25.25,0,0,0,.06-.19V92.75h-.45Zm-3-2.27v.69a6.76,6.76,0,0,1-.93.88,4.37,4.37,0,0,1-1.32.68,5.15,5.15,0,0,1-1.43.19,1.89,1.89,0,0,1-1.3-.38,1.48,1.48,0,0,1-.43-1.14v-.67a2.08,2.08,0,0,1,.1-.63,1.11,1.11,0,0,1,.3-.45,1.21,1.21,0,0,1,.54-.35,3,3,0,0,1,.79-.1h3.68Z"/>
                    <path fill="#213163"
                        d="M112.08,82.73a2.55,2.55,0,0,0-.46-.14,3.2,3.2,0,0,0-.67-.06,3.86,3.86,0,0,0-1.39.25,3.33,3.33,0,0,0-1.17.78,5.66,5.66,0,0,0-1,1.46H107V83a.23.23,0,0,0,0-.13.3.3,0,0,0-.08-.08.23.23,0,0,0-.13,0H104.9V94.3a.37.37,0,0,0,0,.11l0,.08a.2.2,0,0,0,.08,0h2V87a7.79,7.79,0,0,1,.89-1.2,3.59,3.59,0,0,1,1.3-.91,3.86,3.86,0,0,1,1.45-.27,6.38,6.38,0,0,1,.66,0,4.9,4.9,0,0,1,.6.12h.33V83.07a.58.58,0,0,0,0-.21A.23.23,0,0,0,112.08,82.73Z"/>
                    <path fill="#213163"
                        d="M128.32,83.27a2.73,2.73,0,0,0-.91-.57,3.29,3.29,0,0,0-1.09-.19,3.37,3.37,0,0,0-1.2.22,3.32,3.32,0,0,0-1,.67,6.15,6.15,0,0,0-.92,1.13h-.41c0-.11-.05-.23-.08-.33a2.47,2.47,0,0,0-.59-.92,2.51,2.51,0,0,0-.91-.57,3.38,3.38,0,0,0-1.17-.2,2.86,2.86,0,0,0-1.19.25,3.54,3.54,0,0,0-1,.69,7.1,7.1,0,0,0-.93,1.08h-.35c-.06-.24-.1-.46-.14-.64s-.15-.68-.27-1.13h-1.61V94.3a.37.37,0,0,0,0,.11.3.3,0,0,0,0,.08.2.2,0,0,0,.08,0h2V86.6a7,7,0,0,1,.74-.95,4.32,4.32,0,0,1,1.06-.87,2.09,2.09,0,0,1,1-.3,1.39,1.39,0,0,1,.75.18,1.1,1.1,0,0,1,.44.53,2.52,2.52,0,0,1,.14.89V94.3a.23.23,0,0,0,0,.13.24.24,0,0,0,.09.08.27.27,0,0,0,.12,0h1.89V86.49a5.89,5.89,0,0,1,.64-.85,4.43,4.43,0,0,1,1-.86,2.14,2.14,0,0,1,1.06-.3,1.29,1.29,0,0,1,1.08.43,2,2,0,0,1,.34,1.26V94.3a.23.23,0,0,0,0,.13l.09.08a.27.27,0,0,0,.12,0h1.89v-9a3.9,3.9,0,0,0-.22-1.35A2.38,2.38,0,0,0,128.32,83.27Z"/>
                    <path fill="#213163"
                        d="M142.81,92.75h-.1a1.32,1.32,0,0,1-.37,0,.65.65,0,0,1-.27-.15.7.7,0,0,1-.16-.31,1.77,1.77,0,0,1-.06-.49v-5a5.83,5.83,0,0,0-.28-2,3,3,0,0,0-.85-1.31,3.46,3.46,0,0,0-1.47-.74,8.36,8.36,0,0,0-2.14-.24,9.72,9.72,0,0,0-1.74.15A10.18,10.18,0,0,0,134,83a1.71,1.71,0,0,0-.67.31l-.05.07a.31.31,0,0,0,0,.11v1.59h.33a8.93,8.93,0,0,1,1.15-.36,7.21,7.21,0,0,1,1.16-.21,10,10,0,0,1,1.24-.07,5,5,0,0,1,1.62.21,1.48,1.48,0,0,1,.85.71,3.07,3.07,0,0,1,.25,1.38v1h-4a4.63,4.63,0,0,0-1.44.22,3.35,3.35,0,0,0-1.1.62,2.83,2.83,0,0,0-.7,1,3.45,3.45,0,0,0-.24,1.32v.71a3.16,3.16,0,0,0,.42,1.67A2.83,2.83,0,0,0,134,94.38a4,4,0,0,0,1.79.38,5.2,5.2,0,0,0,1.62-.24,3.76,3.76,0,0,0,1.27-.71,4.06,4.06,0,0,0,.92-1.15h.29a2.44,2.44,0,0,0,.13.5,2.12,2.12,0,0,0,.51.76,2.2,2.2,0,0,0,.78.47,2.91,2.91,0,0,0,.95.15H143a.3.3,0,0,0,.18-.05.22.22,0,0,0,.07-.19V92.75h-.46Zm-3-2.27v.69a5.79,5.79,0,0,1-.93.88,4.26,4.26,0,0,1-1.32.68,5.07,5.07,0,0,1-1.42.19,1.89,1.89,0,0,1-1.3-.38,1.45,1.45,0,0,1-.43-1.14v-.67a1.81,1.81,0,0,1,.1-.63.94.94,0,0,1,.3-.45,1.15,1.15,0,0,1,.54-.35,2.92,2.92,0,0,1,.79-.1h3.67Z"/>
                    <path fill="#213163"
                        d="M153.52,83.29A1.69,1.69,0,0,0,153,83a4.05,4.05,0,0,0-1-.32,7.05,7.05,0,0,0-1.47-.14,5.91,5.91,0,0,0-2.71.61,4.56,4.56,0,0,0-1.88,1.79,5.62,5.62,0,0,0-.68,2.85v2a6.2,6.2,0,0,0,.34,2.16,4,4,0,0,0,1,1.59,4.5,4.5,0,0,0,1.66,1,6.9,6.9,0,0,0,2.22.34,8.24,8.24,0,0,0,1.53-.13,4.28,4.28,0,0,0,1-.3,1.75,1.75,0,0,0,.55-.33.2.2,0,0,0,0-.09.25.25,0,0,0,0-.08V92.22h-.33a5.84,5.84,0,0,1-.76.34,4.85,4.85,0,0,1-.91.22,7.63,7.63,0,0,1-1.14.08,4.26,4.26,0,0,1-1.35-.2,2.59,2.59,0,0,1-1-.58,2.63,2.63,0,0,1-.63-1,4.25,4.25,0,0,1-.21-1.4V87.83a4.32,4.32,0,0,1,.37-1.9,2.46,2.46,0,0,1,1.09-1.12,3.66,3.66,0,0,1,1.73-.37,5.61,5.61,0,0,1,1.63.21,6.16,6.16,0,0,1,1.18.5h.33V83.53a.45.45,0,0,0,0-.13A.23.23,0,0,0,153.52,83.29Z"/>
                    <path fill="#213163"
                        d="M159.54,82.79a.27.27,0,0,0-.12,0h-4.08v1.59a.23.23,0,0,0,.07.18.24.24,0,0,0,.17.07h2v9.7a.23.23,0,0,0,.08.16.21.21,0,0,0,.17.08h1.79V83a.24.24,0,0,0,0-.12A.26.26,0,0,0,159.54,82.79Z"/>
                    <path fill="#213163" d="M159.82,77.39h-2.55v2.18a.24.24,0,0,0,.06.17.24.24,0,0,0,.18.07h2.55V77.64a.23.23,0,0,0-.07-.18A.24.24,0,0,0,159.82,77.39Z"/>
                    <path fill="#213163"
                        d="M170.87,83.06a5.16,5.16,0,0,0-2.39-.55,6.13,6.13,0,0,0-1.95.31,4.64,4.64,0,0,0-1.6.93,4.25,4.25,0,0,0-1.07,1.58,5.85,5.85,0,0,0-.39,2.23v2.31a5.68,5.68,0,0,0,.38,2.14,3.93,3.93,0,0,0,1.09,1.53,4.75,4.75,0,0,0,1.75.94,8.25,8.25,0,0,0,2.3.31,10,10,0,0,0,1.78-.15,8.12,8.12,0,0,0,1.42-.36,2.41,2.41,0,0,0,.81-.45.24.24,0,0,0,.06-.09.12.12,0,0,0,0-.08V92.17h-.33a7.92,7.92,0,0,1-1.08.44,6.78,6.78,0,0,1-1.14.27,10.13,10.13,0,0,1-1.45.09,6,6,0,0,1-1.68-.21,2.64,2.64,0,0,1-1.16-.66,2.46,2.46,0,0,1-.55-.95,3.7,3.7,0,0,1-.2-1.28v-.51H173a.17.17,0,0,0,.13,0,.3.3,0,0,0,.08-.08.28.28,0,0,0,0-.13V87.5a5.51,5.51,0,0,0-.65-2.81A4.19,4.19,0,0,0,170.87,83.06Zm.4,4.64v.22h-5.78v-.38a3.38,3.38,0,0,1,.77-2.49,3,3,0,0,1,2.22-.77,3.71,3.71,0,0,1,1.15.17,2.22,2.22,0,0,1,.86.5,2.43,2.43,0,0,1,.59,1,5.11,5.11,0,0,1,.19,1.5Z"/>
                    <path fill="#213163"
                        d="M179,91.6h-2.55c-.13.63-.26,1.26-.38,1.88s-.23,1.23-.34,1.84-.23,1.22-.34,1.84S175.11,98.4,175,99h1.77a.33.33,0,0,0,.15,0,.23.23,0,0,0,.07-.13l.55-1.77c.18-.6.37-1.21.55-1.8s.37-1.19.54-1.77.35-1.13.51-1.66a.27.27,0,0,0,0-.15.11.11,0,0,0,0-.1A.23.23,0,0,0,179,91.6Z"/>
                    <path fill="#213163"
                        d="M199.13,92.75H199a1.35,1.35,0,0,1-.38,0,.72.72,0,0,1-.27-.15.7.7,0,0,1-.16-.31,1.77,1.77,0,0,1-.06-.49v-5a6.12,6.12,0,0,0-.27-2,3,3,0,0,0-.86-1.31,3.4,3.4,0,0,0-1.47-.74,8.36,8.36,0,0,0-2.14-.24,9.88,9.88,0,0,0-1.74.15,9.68,9.68,0,0,0-1.39.32,1.78,1.78,0,0,0-.68.31.43.43,0,0,0,0,.07.18.18,0,0,0,0,.11v1.59h.34a8.34,8.34,0,0,1,1.14-.36,7.21,7.21,0,0,1,1.16-.21,10,10,0,0,1,1.24-.07,5,5,0,0,1,1.62.21,1.45,1.45,0,0,1,.85.71,3.05,3.05,0,0,1,.26,1.38v1h-4a4.63,4.63,0,0,0-1.44.22,3.46,3.46,0,0,0-1.1.62,2.68,2.68,0,0,0-.69,1,3.27,3.27,0,0,0-.25,1.32v.71a3.16,3.16,0,0,0,.42,1.67,2.89,2.89,0,0,0,1.18,1.09,4,4,0,0,0,1.79.38,5.2,5.2,0,0,0,1.62-.24,3.67,3.67,0,0,0,1.27-.71,3.89,3.89,0,0,0,.92-1.15h.29a2.44,2.44,0,0,0,.13.5,2.12,2.12,0,0,0,.51.76,2.29,2.29,0,0,0,.78.47,2.91,2.91,0,0,0,.95.15h.78a.29.29,0,0,0,.17-.05.22.22,0,0,0,.07-.19V92.75h-.45Zm-3-2.27v.69a6.76,6.76,0,0,1-.93.88,4.37,4.37,0,0,1-1.32.68,5.15,5.15,0,0,1-1.43.19,1.89,1.89,0,0,1-1.3-.38,1.48,1.48,0,0,1-.43-1.14v-.67a1.81,1.81,0,0,1,.1-.63,1.11,1.11,0,0,1,.3-.45,1.21,1.21,0,0,1,.54-.35,3,3,0,0,1,.79-.1h3.68Z"/>
                    <path fill="#213163"
                        d="M190.9,78c.29.36.6.73.92,1.1l1.09,1.31a.38.38,0,0,0,.15.12.41.41,0,0,0,.23.06h1.57c-.26-.45-.54-.9-.84-1.37s-.58-.93-.86-1.39-.57-.94-.87-1.41h-2.24a.2.2,0,0,0-.2.14.26.26,0,0,0,.05.24C190.27,77.25,190.6,77.65,190.9,78Z"/>
                    <path fill="#213163"
                        d="M216.26,83a3.88,3.88,0,0,0-2-.52,3.62,3.62,0,0,0-1.41.27,4.28,4.28,0,0,0-1.19.73,4.9,4.9,0,0,0-1,1.17h-.31V83a.23.23,0,0,0,0-.13.3.3,0,0,0-.08-.08.23.23,0,0,0-.13,0h-1.8v16a.19.19,0,0,0,0,.12.24.24,0,0,0,.08.09.28.28,0,0,0,.13,0h1.79V93h.31a5.59,5.59,0,0,0,1,1,3.79,3.79,0,0,0,1.17.59,4.37,4.37,0,0,0,1.37.2,4.11,4.11,0,0,0,1.6-.3,3.32,3.32,0,0,0,1.23-.88,4,4,0,0,0,.79-1.41,5.64,5.64,0,0,0,.28-1.86V86.85a5.06,5.06,0,0,0-.49-2.32A3.52,3.52,0,0,0,216.26,83Zm-.18,7a3.71,3.71,0,0,1-.29,1.54,2.16,2.16,0,0,1-.83.95,2.42,2.42,0,0,1-1.29.32,4.48,4.48,0,0,1-2-.45,4,4,0,0,1-1.31-1.06V86.64a6.63,6.63,0,0,1,.83-1,4.15,4.15,0,0,1,1.2-.85,2.89,2.89,0,0,1,1.25-.28,2.78,2.78,0,0,1,1,.16,2.1,2.1,0,0,1,.74.46,2.25,2.25,0,0,1,.51.85,3.81,3.81,0,0,1,.17,1.23Z"/>
                    <path fill="#213163"
                        d="M229.5,83.89a4.84,4.84,0,0,0-1.61-1,5.47,5.47,0,0,0-2.06-.37,5.31,5.31,0,0,0-2.59.63,4.66,4.66,0,0,0-1.81,1.74,5.09,5.09,0,0,0-.65,2.6v2.45a5.33,5.33,0,0,0,.35,1.95,4.28,4.28,0,0,0,1,1.54,4.5,4.5,0,0,0,1.6,1,5.68,5.68,0,0,0,2.06.36,5.18,5.18,0,0,0,2.59-.64,4.62,4.62,0,0,0,1.81-1.75,5,5,0,0,0,.65-2.58V87.36a5.4,5.4,0,0,0-.35-2A4.22,4.22,0,0,0,229.5,83.89Zm-.63,5.87a3.33,3.33,0,0,1-.37,1.59,2.9,2.9,0,0,1-1.06,1.14,3.21,3.21,0,0,1-1.72.43,2.86,2.86,0,0,1-1.16-.22,2.54,2.54,0,0,1-.9-.6,2.94,2.94,0,0,1-.63-1,3.45,3.45,0,0,1-.23-1.32V87.54a3.57,3.57,0,0,1,.36-1.62,2.9,2.9,0,0,1,1.07-1.13,3.23,3.23,0,0,1,1.71-.42,3.47,3.47,0,0,1,1.2.2,2.7,2.7,0,0,1,1.52,1.58,4.07,4.07,0,0,1,.21,1.37Z"/>
                    <path fill="#213163"
                        d="M241.32,82.73a2.55,2.55,0,0,0-.46-.14,3.2,3.2,0,0,0-.67-.06,3.9,3.9,0,0,0-1.39.25,3.33,3.33,0,0,0-1.17.78,5.92,5.92,0,0,0-1,1.46h-.35V83a.22.22,0,0,0,0-.13l-.07-.08a.26.26,0,0,0-.14,0h-1.88V94.3a.37.37,0,0,0,0,.11.3.3,0,0,0,0,.08l.08,0h2V87a7.79,7.79,0,0,1,.88-1.2,3.68,3.68,0,0,1,1.3-.91,3.86,3.86,0,0,1,1.45-.27,6.38,6.38,0,0,1,.66,0,4.25,4.25,0,0,1,.6.12h.33V83.07a.58.58,0,0,0,0-.21A.23.23,0,0,0,241.32,82.73Z"/>
                    <path fill="#213163"
                        d="M251.46,82.76h-4.07V79.28a.16.16,0,0,0,0-.12.24.24,0,0,0-.08-.09.28.28,0,0,0-.13,0h-1.79v3.73h-2.24v1.66a.29.29,0,0,0,0,.09l.06.07a.24.24,0,0,0,.08.06l.09,0h2v5.41a6.36,6.36,0,0,0,.29,2,3.32,3.32,0,0,0,.86,1.39,3.52,3.52,0,0,0,1.4.8,6.39,6.39,0,0,0,1.9.25h1.66a.24.24,0,0,0,.19-.06.28.28,0,0,0,.06-.18V92.61h-1.82a4.4,4.4,0,0,1-1.1-.12,1.62,1.62,0,0,1-.74-.41,2,2,0,0,1-.51-.86,5,5,0,0,1-.15-1.33V84.66h4.32V83a.24.24,0,0,0-.07-.18A.24.24,0,0,0,251.46,82.76Z"/>
                    <path fill="#213163"
                        d="M260.13,80.45a.59.59,0,0,0,.23,0,.44.44,0,0,0,.14-.13l1.11-1.3.92-1.11,1-1.2a.21.21,0,0,0,.07-.24c0-.09-.1-.13-.22-.13h-2.22c-.28.47-.56.94-.85,1.4l-.86,1.4-.85,1.36h1.55Z"/>
                    <path fill="#213163"
                        d="M261.88,83.06a5.23,5.23,0,0,0-2.4-.55,6.22,6.22,0,0,0-2,.31,4.69,4.69,0,0,0-1.59.93,4.27,4.27,0,0,0-1.08,1.58,6.05,6.05,0,0,0-.38,2.23v2.31a5.68,5.68,0,0,0,.37,2.14,4,4,0,0,0,1.1,1.53,4.67,4.67,0,0,0,1.75.94,8.18,8.18,0,0,0,2.29.31,10.13,10.13,0,0,0,1.79-.15,8,8,0,0,0,1.41-.36,2.41,2.41,0,0,0,.81-.45l.07-.09a.24.24,0,0,0,0-.08V92.17h-.33a7.54,7.54,0,0,1-1.09.44,6.34,6.34,0,0,1-1.14.27,10,10,0,0,1-1.45.09,6,6,0,0,1-1.67-.21,2.59,2.59,0,0,1-1.16-.66,2.47,2.47,0,0,1-.56-.95,4,4,0,0,1-.2-1.28v-.51H264a.2.2,0,0,0,.14,0l.08-.08a.28.28,0,0,0,0-.13V87.5a5.41,5.41,0,0,0-.66-2.81A4.11,4.11,0,0,0,261.88,83.06Zm.39,4.64v.22h-5.78v-.38a3.38,3.38,0,0,1,.78-2.49,3,3,0,0,1,2.21-.77,3.71,3.71,0,0,1,1.15.17,2.34,2.34,0,0,1,.87.5,2.54,2.54,0,0,1,.59,1,5.5,5.5,0,0,1,.18,1.5Z"/>
                    <path fill="#213163"
                        d="M274.77,83.06a5.16,5.16,0,0,0-2.39-.55,6.13,6.13,0,0,0-1.95.31,4.64,4.64,0,0,0-1.6.93,4.25,4.25,0,0,0-1.07,1.58,5.85,5.85,0,0,0-.39,2.23v2.31a5.68,5.68,0,0,0,.38,2.14,3.93,3.93,0,0,0,1.09,1.53,4.75,4.75,0,0,0,1.75.94,8.2,8.2,0,0,0,2.3.31,10,10,0,0,0,1.78-.15,8.12,8.12,0,0,0,1.42-.36,2.41,2.41,0,0,0,.81-.45.24.24,0,0,0,.06-.09.12.12,0,0,0,0-.08V92.17h-.33a7.92,7.92,0,0,1-1.08.44,6.78,6.78,0,0,1-1.14.27A10.18,10.18,0,0,1,273,93a6,6,0,0,1-1.67-.21,2.37,2.37,0,0,1-1.72-1.61,4,4,0,0,1-.19-1.28v-.51h7.53a.17.17,0,0,0,.13,0,.3.3,0,0,0,.08-.08.28.28,0,0,0,0-.13V87.5a5.51,5.51,0,0,0-.65-2.81A4.19,4.19,0,0,0,274.77,83.06Zm.4,4.64v.22h-5.78v-.38a3.38,3.38,0,0,1,.77-2.49,3,3,0,0,1,2.22-.77,3.75,3.75,0,0,1,1.15.17,2.22,2.22,0,0,1,.86.5,2.43,2.43,0,0,1,.59,1,5.11,5.11,0,0,1,.19,1.5Z"/>
                    <path fill="#213163"
                        d="M295.92,78a.21.21,0,0,0-.13,0H294v6.58h-.33a5,5,0,0,0-.92-1.09,3.81,3.81,0,0,0-1.15-.67,3.86,3.86,0,0,0-1.41-.24,4.1,4.1,0,0,0-1.59.31,3.67,3.67,0,0,0-1.24.89,4.12,4.12,0,0,0-.8,1.39,6,6,0,0,0-.27,1.87v3.47a5.66,5.66,0,0,0,.27,1.82,3.91,3.91,0,0,0,.79,1.35,3.32,3.32,0,0,0,1.23.85,4,4,0,0,0,1.56.3,4.09,4.09,0,0,0,1.41-.24,3.78,3.78,0,0,0,1.18-.69,4.3,4.3,0,0,0,.94-1.08H294V94.3a.23.23,0,0,0,0,.13.24.24,0,0,0,.09.08.27.27,0,0,0,.12,0H296V78.17a.22.22,0,0,0,0-.13A.3.3,0,0,0,295.92,78ZM294,87.22V90.9a6.15,6.15,0,0,1-.8.92,4,4,0,0,1-1.22.76,3.43,3.43,0,0,1-1.26.23,3.05,3.05,0,0,1-1-.15,2,2,0,0,1-.75-.47,2.42,2.42,0,0,1-.5-.85,3.78,3.78,0,0,1-.19-1.23V87.3a3.62,3.62,0,0,1,.3-1.56,2.11,2.11,0,0,1,.85-1,2.47,2.47,0,0,1,1.27-.32,3.45,3.45,0,0,1,1.9.59A5.15,5.15,0,0,1,294,86.43Z"/>
                    <path fill="#213163"
                        d="M306.8,83.06a5.16,5.16,0,0,0-2.39-.55,6.22,6.22,0,0,0-2,.31,4.51,4.51,0,0,0-1.59.93,4.27,4.27,0,0,0-1.08,1.58,5.85,5.85,0,0,0-.39,2.23v2.31a5.68,5.68,0,0,0,.38,2.14,4,4,0,0,0,1.1,1.53,4.67,4.67,0,0,0,1.75.94,8.13,8.13,0,0,0,2.29.31,10,10,0,0,0,1.78-.15,7.9,7.9,0,0,0,1.42-.36,2.41,2.41,0,0,0,.81-.45l.07-.09a.24.24,0,0,0,0-.08V92.17h-.33a8,8,0,0,1-1.09.44,6.34,6.34,0,0,1-1.14.27A10.13,10.13,0,0,1,305,93a6.07,6.07,0,0,1-1.68-.21,2.7,2.7,0,0,1-1.16-.66,2.59,2.59,0,0,1-.55-.95,4,4,0,0,1-.2-1.28v-.51H309a.17.17,0,0,0,.13,0,.3.3,0,0,0,.08-.08.28.28,0,0,0,0-.13V87.5a5.41,5.41,0,0,0-.66-2.81A4.08,4.08,0,0,0,306.8,83.06Zm.4,4.64v.22h-5.78v-.38a3.42,3.42,0,0,1,.77-2.49,3,3,0,0,1,2.22-.77,3.71,3.71,0,0,1,1.15.17,2.34,2.34,0,0,1,.87.5,2.41,2.41,0,0,1,.58,1,5.11,5.11,0,0,1,.19,1.5Z"/>
                    <path fill="#213163"
                        d="M332.75,83.27a2.68,2.68,0,0,0-.92-.57,3.18,3.18,0,0,0-1.08-.19,3.37,3.37,0,0,0-1.2.22,3.32,3.32,0,0,0-1,.67,6.15,6.15,0,0,0-.92,1.13h-.41c0-.11-.05-.23-.08-.33a2.6,2.6,0,0,0-.59-.92,2.51,2.51,0,0,0-.91-.57,3.42,3.42,0,0,0-1.17-.2,2.86,2.86,0,0,0-1.19.25,3.54,3.54,0,0,0-1.05.69,7.1,7.1,0,0,0-.93,1.08h-.35c-.06-.24-.1-.46-.14-.64s-.15-.68-.27-1.13H318.9V94.3a.37.37,0,0,0,0,.11.3.3,0,0,0,0,.08l.08,0h2V86.6a7.84,7.84,0,0,1,.73-.95,4.55,4.55,0,0,1,1.07-.87,2.09,2.09,0,0,1,1-.3,1.39,1.39,0,0,1,.75.18,1.08,1.08,0,0,1,.43.53,2.3,2.3,0,0,1,.15.89V94.3a.23.23,0,0,0,0,.13.24.24,0,0,0,.09.08.27.27,0,0,0,.12,0h1.89V86.49a5.89,5.89,0,0,1,.64-.85,4.24,4.24,0,0,1,1-.86,2.14,2.14,0,0,1,1.06-.3,1.29,1.29,0,0,1,1.08.43,2,2,0,0,1,.34,1.26V94.3a.23.23,0,0,0,0,.13l.09.08a.27.27,0,0,0,.12,0h1.89v-9a3.9,3.9,0,0,0-.22-1.35A2.38,2.38,0,0,0,332.75,83.27Z"/>
                    <path fill="#213163"
                        d="M347.24,92.75h-.1a1.32,1.32,0,0,1-.37,0,.59.59,0,0,1-.27-.15.83.83,0,0,1-.17-.31,2.31,2.31,0,0,1-.05-.49v-5a5.83,5.83,0,0,0-.28-2,3,3,0,0,0-.85-1.31,3.52,3.52,0,0,0-1.47-.74,8.36,8.36,0,0,0-2.14-.24,9.72,9.72,0,0,0-1.74.15,10.18,10.18,0,0,0-1.4.32,1.71,1.71,0,0,0-.67.31l-.05.07a.31.31,0,0,0,0,.11v1.59H338a9.31,9.31,0,0,1,1.14-.36,7.54,7.54,0,0,1,1.17-.21,10,10,0,0,1,1.24-.07,5,5,0,0,1,1.62.21,1.48,1.48,0,0,1,.85.71,3.07,3.07,0,0,1,.25,1.38v1h-4a4.63,4.63,0,0,0-1.44.22,3.35,3.35,0,0,0-1.1.62,2.83,2.83,0,0,0-.7,1,3.45,3.45,0,0,0-.24,1.32v.71a3.16,3.16,0,0,0,.42,1.67,2.86,2.86,0,0,0,1.17,1.09,4,4,0,0,0,1.8.38,5.2,5.2,0,0,0,1.62-.24,3.76,3.76,0,0,0,1.27-.71,4.06,4.06,0,0,0,.92-1.15h.29a2.44,2.44,0,0,0,.13.5,2.12,2.12,0,0,0,.51.76,2.25,2.25,0,0,0,.77.47,3,3,0,0,0,1,.15h.77a.3.3,0,0,0,.18-.05.26.26,0,0,0,.07-.19V92.75h-.46Zm-3-2.27v.69a6.24,6.24,0,0,1-.93.88,4.37,4.37,0,0,1-1.32.68,5.09,5.09,0,0,1-1.43.19,1.88,1.88,0,0,1-1.29-.38,1.45,1.45,0,0,1-.43-1.14v-.67a1.81,1.81,0,0,1,.1-.63.94.94,0,0,1,.3-.45,1.11,1.11,0,0,1,.54-.35,2.9,2.9,0,0,1,.78-.1h3.68Z"/>
                    <path fill="#213163"
                        d="M353.07,82.79a.27.27,0,0,0-.12,0h-4.08v1.59a.23.23,0,0,0,.07.18.24.24,0,0,0,.17.07h2v9.7a.23.23,0,0,0,.08.16.21.21,0,0,0,.17.08h1.79V83a.24.24,0,0,0,0-.12A.38.38,0,0,0,353.07,82.79Z"/>
                    <path fill="#213163" d="M353.34,77.39H350.8v2.18a.24.24,0,0,0,.06.17.24.24,0,0,0,.18.07h2.55V77.64a.23.23,0,0,0-.07-.18A.25.25,0,0,0,353.34,77.39Z"/>
                    <path fill="#213163"
                        d="M366.47,84.5a3.15,3.15,0,0,0-.6-1.06,2.62,2.62,0,0,0-1-.68,3.34,3.34,0,0,0-1.34-.25,3.72,3.72,0,0,0-1.46.28,4.55,4.55,0,0,0-1.28.77,7.66,7.66,0,0,0-1.09,1.17h-.34V83a.23.23,0,0,0,0-.13.3.3,0,0,0-.08-.08.23.23,0,0,0-.13,0h-1.79V94.3a.37.37,0,0,0,0,.11s0,0,0,.08a.2.2,0,0,0,.08,0h1.9V86.45c.23-.25.46-.48.68-.68a6.06,6.06,0,0,1,1-.73,4.37,4.37,0,0,1,1-.43,3.19,3.19,0,0,1,1-.15,1.7,1.7,0,0,1,1.23.43,1.83,1.83,0,0,1,.43,1.34V94.3a.22.22,0,0,0,0,.13.24.24,0,0,0,.09.08.21.21,0,0,0,.12,0h1.79v-8.7A4.05,4.05,0,0,0,366.47,84.5Z"/>
                    <path fill="#213163" fillRule="evenodd"
                        d="M449.17,43.92c7.13,2.37,13.38-.56,12.74,2.69s-6.52,5.12-12.24,4.88c-5.87-.25-10.59-3.2-15.28-6.24-2.81-1.82-3.56-.83-4.5,5.24-3.09,20-6,39-8.59,53.6-.75,4.16-4.31,3.62-4.31-.28V68.68c0-5.77-3.26-6.12-5.42-1-5.84,13.92-17.25,24.79-30.79,14.78-2-1.5-1.23-3.34,1-2.5,10.42,3.93,17.6-5.54,20.87-23,.34-1.84,1.73-7,6.38-3.2,5.81,4.75,14.81,5.31,16.16,2.33,1-2.29-3.3-3.57-7-6.23-3.89-2.77-9.48-9.41-8.79-16.26s7.05-10.12,15-9.4C437.19,25.36,438.59,40.4,449.17,43.92Z"/>
                    <path fill="#6dbf51" d="M471.37,19.69h-6.7V13a4.17,4.17,0,1,0-8.34,0v6.69h-6.7a4.17,4.17,0,0,0,0,8.34h6.7v6.7a4.17,4.17,0,0,0,8.34,0V28h6.7a4.17,4.17,0,1,0,0-8.34Z"/>
                    <path fill="#213163" d="M437.26,10.53A10.47,10.47,0,1,1,426.85,0,10.46,10.46,0,0,1,437.26,10.53Z"/>
                </g>
            </g>
        </svg>
    );
};
