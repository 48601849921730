import { FC, MouseEventHandler, useEffect, useState } from 'react';

import { Logo } from '../../assets/svg';
import { LogoNP } from '../../assets/svg';
import styles from './styles.module.css';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Navbar: FC<{
    isResults?: boolean,
    onlyLogo?: boolean,
    buttonText?: string,
    onClick?: MouseEventHandler
}> = ({ isResults = false, onlyLogo = false, buttonText = '', onClick = () => {} }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const goToProfile = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.navbar}>
            <div className={styles.logo_container}>
                <div onClick={goToProfile}>
                    {localStorage.getItem('pharmacyId') === 'newpharma' ? 
                        <LogoNP width={screenWidth <= 500 ? '120px' : '200px'} /> :
                        <Logo width={screenWidth <= 500 ? '89px' : '101px'} />
                    }
                </div>
                {localStorage.getItem('pharmacy') && <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <span className={styles.and}>&</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {localStorage.getItem('pharmacyIcon') && <img src={localStorage.getItem('pharmacyIcon') || ''} alt={localStorage.getItem('pharmacy') || ''} width="auto" height="32px" />}
                        <span className={styles.text} style={{ textDecoration: 'none', color: '#2E3767' }}>{localStorage.getItem('pharmacy') === 'teva' ? '' : localStorage.getItem('pharmacy')}</span>
                    </div>
                </div>}
            </div>
            {!onlyLogo && <div className={styles.actions}>
                {(isResults && localStorage.getItem('pharmacyId')) && <div onClick={onClick} style={{ cursor: 'pointer' }}>
                    <span className={styles.text}>{buttonText}</span>
                </div>}
                <LanguageSwitcher />
            </div>}
        </div>
    );
};

export default Navbar;
