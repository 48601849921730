import { FC, LegacyRef } from "react";

import styles from "./styles.module.css";
import Navbar from "../../components/Navbar";
import { Battery, UserGuard } from "../../assets/svg";
import { Button } from "../../components/Button";
import { t } from 'i18next';
import { Trans } from "react-i18next";
import ResultsImage from '../../assets/images/results-image.png';
import { Link } from "react-router-dom";

const ExportResults: FC<{
    isDetailedResults: boolean,
    resultsData: any,
    user: any,
    exportRef: LegacyRef<HTMLDivElement> | undefined,
}> = ({ isDetailedResults, resultsData, user, exportRef }) => {
    return (
        <div ref={exportRef} className={styles.result_screen_export}>
            <Navbar isResults={false} onlyLogo={true} />
            <div className={styles.results} style={{ minHeight: 'auto' }}>
                <div style={isDetailedResults ? { display: 'none' } : { display: 'flex', width: '100%' }}>
                    <div className={styles.top_container}>
                        <div className={styles.results_header}>
                            <h2 className={styles.title}>{t('results.header.energy.title')}</h2>
                            <div className={styles.paragraphs_container}>
                                <p className={`text-center ${styles.paragraph} ${styles.results_header_size}`}><Trans i18nKey="results.header.energy.description" /></p>
                            </div>
                            <div className={`${styles.header_info} ${styles.results_header_size}`}>
                                <div className='d-flex'>
                                    <UserGuard width='34px' />
                                </div>
                                <p className={`${styles.info_text} fs-italic`}>{t('results.header.energy.info')}</p>
                            </div>
                        </div>
                        <div className={styles.results_info}>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.physical.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.physical.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.PHYSICAL_SCORE)} width='5rem' />
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.mental.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.mental.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.MENTAL_SCORE)} width='5rem' />
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.metabolic.title')}</h3>
                                    <p className={styles.paragraph_small}>{t('results.metabolic.description')}</p>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round((resultsData.PHYSIO_SCORE + resultsData.VITAL_SCORE) / 2)} width='5rem' />
                                </div>
                            </div>
                        </div>
                        <div className={styles.preFooter}>
                            <p className={styles.preFooterTitle}>{t('results.preFooter.title')}</p>
                            <div className={styles.preFooterContent}>
                                <p className={styles.preFooterDescription}><Trans i18nKey="results.preFooter.description" /></p>
                                <img src={ResultsImage} alt="Yoboo" className={styles.preFooterImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.results_footer} style={{ marginTop: '20px' }}>
                    <div className={styles.results_footer_container}>
                        <div className={`${styles.paragraphs_container} ${styles.w_60}`}>
                            <h2 className={styles.bottom_title} style={{ color: '#FFFFFF' }}>{t('results.footer.title')}</h2>
                            <p className={styles.paragraph_small} style={{ color: '#FFFFFF' }}>{t('results.footer.description')}</p>
                        </div>
                        <Link to="/proceed">
                            <Button className={styles.button_container} text={t('results.footer.button')} />
                        </Link>
                    </div>
                </div>
        </div>
    );
};

export default ExportResults;
