export const Logo = ({ width = "100%" }: { width: string }) => {
    return (
        <svg width={width} height="100%" viewBox="0 0 759 240" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M689.825 63.8803V43.0533C664.793 43.0533 643.404 55.4155 630.887 77.1168L649.067 87.6265C657.202 73.5164 672.296 63.9711 689.825 63.8803Z" fill="#9CDDFF"/>
            <path d="M118.468 45.8086V71.1028L118.228 111.193C118.228 124.077 112.268 138.393 103.45 147.7C93.6774 157.96 81.5197 162.494 67.6954 162.494C39.1765 162.494 19.406 140.63 17.7628 113.439V45.8086H0.239098V71.1028L0 111.193L0.0050872 111.22C0.0050872 111.29 0 111.359 0 111.429C0 130.519 6.6744 147.223 20.4989 160.346C32.8938 172.038 50.2955 179.438 67.4553 179.438C87.1021 179.438 108.724 170.657 117.629 152.29V174.003C115.985 201.196 96.2141 223.059 67.6962 223.059C67.6954 223.059 67.6954 223.059 67.6954 223.059C67.6954 223.059 67.6954 223.059 67.6945 223.059C43.9475 223.059 26.2772 207.893 20.0775 187.121H1.53718C4.21475 199.965 10.4635 211.385 20.4989 220.912C26.1584 226.248 32.8676 230.679 40.1144 233.931C44.5978 236.029 49.3739 237.573 54.2448 238.575C54.5584 238.642 54.8723 238.703 55.1859 238.766C55.8117 238.886 56.4357 239.003 57.0623 239.105C58.1059 239.281 59.1521 239.431 60.2009 239.553C60.2289 239.557 60.2569 239.561 60.2849 239.564C62.6717 239.844 65.0662 240 67.4553 240C67.5352 240 67.6157 239.996 67.6954 239.996C67.775 239.996 67.8556 240 67.9343 240C70.3245 240 72.7189 239.844 75.1056 239.564C75.1336 239.561 75.1616 239.557 75.1896 239.553C76.2384 239.431 77.2848 239.281 78.3284 239.105C78.9559 239.003 79.5791 238.886 80.2048 238.766C80.5185 238.703 80.833 238.642 81.146 238.575C86.0169 237.573 90.7938 236.029 95.2764 233.931C102.524 230.679 109.233 226.248 114.892 220.912C128.411 208.077 135.074 191.815 135.364 173.25L135.392 173.188V162.494V106.373V45.8086H118.468Z" fill="#0E62BE"/>
            <path d="M173.798 111.91C173.798 139.829 196.68 162.497 224.57 162.497C253.651 162.497 274.627 138.636 274.627 110.241C274.627 95.9236 269.858 84.2311 259.372 74.2088C250.076 65.3808 237.441 59.8926 224.57 59.8926C195.727 59.8926 173.798 83.5138 173.798 111.91ZM292.265 110.241C292.265 147.464 262.231 179.441 224.57 179.441C187.624 179.441 156.16 149.373 156.16 111.91C156.16 73.9694 186.432 42.9479 224.57 42.9479C260.8 42.9479 292.265 73.9694 292.265 110.241Z" fill="#0E62BE"/>
            <path d="M344.153 75.6419C333.904 85.9036 330.329 97.833 330.329 112.15C330.329 141.264 353.927 162.5 382.531 162.5C410.895 162.5 433.777 139.354 433.777 110.96C433.777 81.3702 410.418 59.8955 381.337 59.8955C367.276 59.8955 353.927 65.6205 344.153 75.6419ZM313.881 7.62939e-05H331.521V70.1537C340.101 52.0189 361.555 42.9508 380.861 42.9508C418.761 42.9508 451.417 71.8242 451.417 110.48C451.417 129.333 443.076 146.036 435.921 153.91C417.808 174.432 401.599 179.444 382.531 179.444C363.699 179.444 340.817 170.852 330.566 153.91V176.339H313.881V7.62939e-05Z" fill="#0E62BE"/>
            <path d="M486.118 111.91C486.118 139.829 509.002 162.497 536.891 162.497C565.97 162.497 586.948 138.636 586.948 110.241C586.948 95.9236 582.178 84.2311 571.692 74.2088C562.393 65.3808 549.761 59.8926 536.891 59.8926C508.047 59.8926 486.118 83.5138 486.118 111.91ZM604.586 110.241C604.586 147.464 574.552 179.441 536.891 179.441C499.945 179.441 468.48 149.373 468.48 111.91C468.48 73.9694 498.751 42.9479 536.891 42.9479C573.122 42.9479 604.586 73.9694 604.586 110.241Z" fill="#0E62BE"/>
            <path d="M690.476 63.8835C707.359 63.8835 722.739 73.6096 731.011 87.6184L749.168 77.1259C736.65 55.4246 715.259 43.0624 690.227 43.0624V63.8894C690.311 63.8887 690.394 63.8835 690.476 63.8835Z" fill="#BAE089"/>
            <path d="M749.115 77.1158L730.958 87.6082C734.993 94.4383 737.348 102.28 737.348 110.521C737.348 119.226 734.972 127.513 730.828 134.678L749.115 145.246C761.628 123.545 761.628 98.8188 749.115 77.1158Z" fill="#99D62F"/>
            <path d="M730.881 134.69C722.743 148.765 707.773 158.5 690.476 158.5C690.394 158.5 690.311 158.496 690.227 158.493V179.322C715.259 179.322 736.65 166.96 749.168 145.257L730.881 134.69Z" fill="#26A749"/>
            <path d="M648.943 134.82L630.887 145.257C643.404 166.96 664.793 179.322 689.825 179.322V158.493C672.631 158.403 657.187 148.922 648.943 134.82Z" fill="#0E62BE"/>
            <path d="M642.666 111.678C642.666 102.921 645.004 94.7015 649.083 87.6254L630.902 77.1158C618.387 98.8188 618.387 123.545 630.902 145.246L648.959 134.808C644.978 127.994 642.666 120.11 642.666 111.678Z" fill="#4DB3FF"/>
        </svg>
    );
};
